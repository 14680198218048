import React from 'react';
import { Banner } from 'spoton-lib';

import Styles from './ReusableBanner.module.scss';
import { ReusableBannerProps } from './ReusableBanner.types';

export function ReusableBanner(props: ReusableBannerProps) {
    const {
        message,
        title,
        variant = 'informative',
        className = '',
        actions,
    } = props;

    return (
        <Banner
            message={message}
            className={`${Styles[`ReusableBanner_${variant}Banner`] ?? ''} ${Styles.ReusableBanner ?? ''} ${className}`}
            title={title}
            variant={variant}
            actions={actions}
        />
    );
}
