import React from 'react';
import { Text } from 'spoton-lib';

import { ReusableModal } from 'features/common';
import { FailBadge } from 'features/merchant_onboarding/assets';
import styles from './OpenStoreErrorModal.module.scss';
import { ActiveModal } from 'features/merchant_onboarding/MerchantOnboarding.types';
import { OpenStoreErrorModalProps } from './OpenStoreErrorModal.types';

export function OpenStoreErrorModal(props: OpenStoreErrorModalProps) {
    const { activeModal, setActiveModal } = props;
    return (
        <ReusableModal
            title="Unable to open your store"
            isOpen={activeModal === ActiveModal.OPEN_STORE_ERROR_MODAL}
            onCancel={() => {
                setActiveModal(ActiveModal.NONE);
            }}
            confirmText="Try again"
            onConfirm={() => {
                setActiveModal(ActiveModal.ACTIVATE_INTEGRATION_MODAL);
            }}
            gap="1.625rem"
            contentClassName={styles.OpenStoreErrorModal_content}
        >
            <span>
                <FailBadge />
            </span>
            <Text>
                Please try again. If the problem continues, contact DoorDash
                support at 855-222-8111.{' '}
            </Text>
        </ReusableModal>
    );
}
