// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loader_Loader__PZMfQ{transform-origin:center;animation:Loader_Rotation__c-1lE 2s linear infinite}@keyframes Loader_Rotation__c-1lE{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}`, "",{"version":3,"sources":["webpack://./src/features/merchant_onboarding/assets/loader/Loader.module.scss"],"names":[],"mappings":"AACA,sBACI,uBAAA,CACA,mDAAA,CAGJ,kCACI,GACI,sBAAA,CAGJ,KACI,wBAAA,CAAA","sourcesContent":["/* stylelint-disable */\n.Loader {\n    transform-origin: center;\n    animation: Rotation 2s linear infinite;\n}\n\n@keyframes Rotation {\n    0% {\n        transform: rotate(0deg);\n    }\n\n    100% {\n        transform: rotate(360deg);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Loader": `Loader_Loader__PZMfQ`,
	"Rotation": `Loader_Rotation__c-1lE`
};
export default ___CSS_LOADER_EXPORT___;
