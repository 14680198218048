// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MenuStatusModal_MenuStatusModal_modal__kinhV{width:100%}.MenuStatusModal_MenuStatusModal_modal___content__vqwJC{flex-direction:row}.MenuStatusModal_MenuStatusModal_doorDashMenuLink__WPe8n{gap:.5rem;margin:0;margin:initial;margin-top:.6875rem;font-weight:500;line-height:inherit;text-decoration:none}`, "",{"version":3,"sources":["webpack://./src/features/merchant_onboarding/components/menu_status_modal/MenuStatusModal.module.scss"],"names":[],"mappings":"AAEI,8CACI,UAAA,CAEA,wDACI,kBAAA,CAIR,yDACI,SAAA,CACA,QAAA,CAAA,cAAA,CACA,mBAAA,CACA,eAAA,CACA,mBAAA,CACA,oBAAA","sourcesContent":["/* stylelint-disable */\n.MenuStatusModal {\n    &_modal {\n        width: 100%;\n\n        &___content {\n            flex-direction: row;\n        }\n    }\n\n    &_doorDashMenuLink {\n        gap: 0.5rem;\n        margin: unset;\n        margin-top: 0.6875rem;\n        font-weight: 500;\n        line-height: unset;\n        text-decoration: none;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MenuStatusModal_modal": `MenuStatusModal_MenuStatusModal_modal__kinhV`,
	"MenuStatusModal_modal___content": `MenuStatusModal_MenuStatusModal_modal___content__vqwJC`,
	"MenuStatusModal_doorDashMenuLink": `MenuStatusModal_MenuStatusModal_doorDashMenuLink__WPe8n`
};
export default ___CSS_LOADER_EXPORT___;
