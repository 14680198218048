// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ExternalLinkButton_ExternalLinkButton_button__98fqn{gap:.5rem;padding:.625rem 1.5rem;color:#0f1221;border:0 !important;border-radius:6rem}.ExternalLinkButton_ExternalLinkButton_informativeButton__d89kG{background-color:#eff5ff !important}.ExternalLinkButton_ExternalLinkButton_dangerButton__2nhQR{background-color:#ffddd9 !important}`, "",{"version":3,"sources":["webpack://./src/features/common/components/external_link_button/ExternalLinkButton.module.scss"],"names":[],"mappings":"AACI,qDACI,SAAA,CACA,sBAAA,CACA,aAAA,CACA,mBAAA,CACA,kBAAA,CAGJ,gEACI,mCAAA,CAGJ,2DACI,mCAAA","sourcesContent":[".ExternalLinkButton {\n    &_button {\n        gap: 0.5rem;\n        padding: 0.625rem 1.5rem;\n        color: #0f1221;\n        border: 0 !important;\n        border-radius: 6rem;\n    }\n\n    &_informativeButton {\n        background-color: #eff5ff !important;\n    }\n\n    &_dangerButton {\n        background-color: #ffddd9 !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ExternalLinkButton_button": `ExternalLinkButton_ExternalLinkButton_button__98fqn`,
	"ExternalLinkButton_informativeButton": `ExternalLinkButton_ExternalLinkButton_informativeButton__d89kG`,
	"ExternalLinkButton_dangerButton": `ExternalLinkButton_ExternalLinkButton_dangerButton__2nhQR`
};
export default ___CSS_LOADER_EXPORT___;
