// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LinkWithIcon_LinkWithIcon_link__LsU5f{gap:.5rem;margin:0;margin:initial;font-weight:500;font-size:1rem !important;line-height:1.5rem}`, "",{"version":3,"sources":["webpack://./src/features/common/components/link_with_icon/LinkWithIcon.module.scss"],"names":[],"mappings":"AAEI,uCACI,SAAA,CACA,QAAA,CAAA,cAAA,CACA,eAAA,CACA,yBAAA,CACA,kBAAA","sourcesContent":["/* stylelint-disable */\n.LinkWithIcon {\n    &_link {\n        gap: 0.5rem;\n        margin: unset;\n        font-weight: 500;\n        font-size: 1rem !important;\n        line-height: 1.5rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LinkWithIcon_link": `LinkWithIcon_LinkWithIcon_link__LsU5f`
};
export default ___CSS_LOADER_EXPORT___;
