// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ActivationModal_ActivationModal_content__LeGmm{padding:0 1.5rem}.ActivationModal_ActivationModal_content___banner__5wQzN{border-radius:.75rem}`, "",{"version":3,"sources":["webpack://./src/features/merchant_onboarding/components/activation_modal/ActivationModal.module.scss"],"names":[],"mappings":"AAEI,gDACI,gBAAA,CAEA,yDACI,oBAAA","sourcesContent":["/* stylelint-disable */\n.ActivationModal {\n    &_content {\n        padding: 0 1.5rem;\n\n        &___banner {\n            border-radius: 0.75rem;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ActivationModal_content": `ActivationModal_ActivationModal_content__LeGmm`,
	"ActivationModal_content___banner": `ActivationModal_ActivationModal_content___banner__5wQzN`
};
export default ___CSS_LOADER_EXPORT___;
