import React from 'react';
import { Hyperlink, Text } from 'spoton-lib';

import { useAppSelector } from 'api/store';
import { OnboardingStatus } from 'features/merchant_onboarding/MerchantOnboarding.types';
import { BannerInfo } from './InformationBanner.types';
import { URL } from 'utils/constants';
import styles from './InformationBanner.module.scss';
import { ReusableBanner } from 'features/common';
import {
    getLocaleDate,
    getLocaleTime,
    isToday,
} from 'features/merchant_onboarding/MerchantOnboarding.utility';

export function InformationBanner() {
    const {
        onboardingStatus,
        isError,
        exclusionCode,
        exclusionCodeDetails,
        stepsList,
        isSignupCompleted,
        signupCompletedAt,
    } = useAppSelector((state) => state.merchantOnboarding);

    const getCurrentBannerInfo = (): BannerInfo => {
        if (
            isSignupCompleted &&
            !stepsList[1].isProcessCompleted &&
            signupCompletedAt
        ) {
            return {
                title: 'DoorDash needs 24 hours to review your new account.',
                message: (
                    <Text>
                        Please check back{' '}
                        {!isToday(signupCompletedAt) &&
                            `on ${getLocaleDate(signupCompletedAt)}`}{' '}
                        at {getLocaleTime(signupCompletedAt)} to complete your
                        Online Ordering integration.
                    </Text>
                ),
            };
        } else if (onboardingStatus === OnboardingStatus.MENU_REQUESTED) {
            return {
                title: 'Your menu is being synced to DoorDash.',
                message: (
                    <Text>
                        This may take a few minutes. If you’re waiting for more
                        than 10 minutes,
                        <Hyperlink
                            className={styles.InformationBanner_link}
                            href={URL.DOORDASH_CONTACT_SUPPORT_URL}
                            target="_blank"
                        >
                            contact DoorDash support.
                        </Hyperlink>
                    </Text>
                ),
            };
        } else if (onboardingStatus === OnboardingStatus.MENU_BLOCK) {
            return {
                title: 'We were unable to sync your menu',
                message: (
                    <Text>
                        Try again or
                        <Hyperlink
                            className={styles.InformationBanner_link}
                            href={URL.DOORDASH_CONTACT_SUPPORT_URL}
                            target="_blank"
                        >
                            contact DoorDash support.
                        </Hyperlink>
                    </Text>
                ),
            };
        } else if (exclusionCode === 'STORE_NOT_ACTIVE') {
            return {
                title: 'We were unable to open your store',
                message: (
                    <Text>
                        {exclusionCode}: {exclusionCodeDetails}
                    </Text>
                ),
            };
        } else if (onboardingStatus === OnboardingStatus.ACTIVATION_BLOCK) {
            return {
                title: 'We were unable to activate your DoorDash account',
                message: (
                    <Text>
                        Please try again. If the problem continues, contact
                        DoorDash support at 855-222-8111.
                    </Text>
                ),
            };
        } else if (
            onboardingStatus === OnboardingStatus.ABANDONED &&
            exclusionCode
        ) {
            return {
                message: (
                    <Text>
                        {exclusionCode}: {exclusionCodeDetails}
                    </Text>
                ),
            };
        }
    };

    const bannerInfo = getCurrentBannerInfo();
    if (bannerInfo) {
        return (
            <ReusableBanner
                title={bannerInfo.title}
                message={bannerInfo.message}
                variant={isError ? 'danger' : 'informative'}
            />
        );
    } else {
        return null;
    }
}
