import React, { useEffect, useState } from 'react';
import { Text } from 'spoton-lib';

import { ReusableBanner, ReusableModal } from 'features/common';
import styles from './ActivationModal.module.scss';
import { ActiveModal } from 'features/merchant_onboarding/MerchantOnboarding.types';
import { ActivationModalProps } from './ActivationModal.types';

export function ActivationModal(props: ActivationModalProps) {
    const { activeModal, setActiveModal, onClickActivationBtn } = props;

    const [clickedButton, setClickedButton] = useState<string>('');

    useEffect(() => {
        if (activeModal !== ActiveModal.ACTIVATE_INTEGRATION_MODAL) {
            setClickedButton('');
        }
    }, [activeModal]);

    return (
        <ReusableModal
            isOpen={activeModal === ActiveModal.ACTIVATE_INTEGRATION_MODAL}
            title="Start receiving DoorDash orders"
            confirmText="Activate"
            onCancel={() => {
                setActiveModal(ActiveModal.NONE);
            }}
            showCancelBtn={false}
            onConfirm={() => {
                onClickActivationBtn(true);
                setClickedButton('confirmButton');
            }}
            confirmButtonColor="#2D71E8"
            isConfirmBtnLoading={clickedButton === 'confirmButton'}
            isSecondaryConfirmBtnLoading={
                clickedButton === 'secondaryConfirmButton'
            }
            secondaryConfirmButtonText="I’m not ready"
            onClickSecondaryConfirmButton={() => {
                onClickActivationBtn(false);
                setClickedButton('secondaryConfirmButton');
            }}
            gap="1rem"
            contentClassName={styles.ActivationModal_content}
        >
            <Text>
                Set your status to open to start receiving DoorDash orders on
                your SpotOn POS during business hours. You can update your
                status anytime in settings.
            </Text>
            <ReusableBanner
                message="If your DoorDash account is not yet activated, your account and any trial period will be activated."
                className={styles.ActivationModal_content___banner}
            />
        </ReusableModal>
    );
}
