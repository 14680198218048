// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DdAccountCreateModal_DdAccountCreateModal_link__DSsvU{gap:.5rem;margin:0;margin:initial;font-weight:500;font-size:1rem !important;line-height:1.5rem}`, "",{"version":3,"sources":["webpack://./src/features/merchant_onboarding/components/dd_account_create_modal/DdAccountCreateModal.module.scss"],"names":[],"mappings":"AAEI,uDACI,SAAA,CACA,QAAA,CAAA,cAAA,CACA,eAAA,CACA,yBAAA,CACA,kBAAA","sourcesContent":["/* stylelint-disable */\n.DdAccountCreateModal {\n    &_link {\n        gap: 0.5rem;\n        margin: unset;\n        font-weight: 500;\n        font-size: 1rem !important;\n        line-height: 1.5rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DdAccountCreateModal_link": `DdAccountCreateModal_DdAccountCreateModal_link__DSsvU`
};
export default ___CSS_LOADER_EXPORT___;
