import React from 'react';

import styles from './Loader.module.scss';

export function Loader() {
    return (
        <svg
            width="46"
            height="47"
            viewBox="0 0 46 47"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.5211 14.1924C17.3803 14.1924 13.2129 18.3598 13.2129 23.5006C13.2129 28.6414 17.3803 32.8089 22.5211 32.8089C27.662 32.8089 31.8294 28.6414 31.8294 23.5006C31.8294 18.3598 27.662 14.1924 22.5211 14.1924ZM1.80273 23.5006C1.80273 12.0582 11.0787 2.78223 22.5211 2.78223C33.9636 2.78223 43.2395 12.0582 43.2395 23.5006C43.2395 34.9431 33.9636 44.219 22.5211 44.219C11.0787 44.219 1.80273 34.9431 1.80273 23.5006Z"
                fill="#94BDFF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31.8272 23.5006C31.8272 18.3598 27.6597 14.1924 22.5189 14.1924C17.3781 14.1924 13.2106 18.3598 13.2106 23.5006C13.2106 26.6514 10.6564 29.2057 7.50556 29.2057C4.35474 29.2057 1.8005 26.6514 1.8005 23.5006C1.8005 12.0582 11.0764 2.78223 22.5189 2.78223C33.9614 2.78223 43.2373 12.0582 43.2373 23.5006C43.2373 29.0824 41.0224 34.1588 37.4357 37.8791C35.2488 40.1475 31.6372 40.2135 29.3689 38.0267C27.1005 35.8398 27.0345 32.2282 29.2213 29.9598C30.8404 28.2804 31.8272 26.0117 31.8272 23.5006Z"
                fill="#1769FF"
                className={styles.Loader}
            />
        </svg>
    );
}
