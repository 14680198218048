// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReusableBanner_ReusableBanner__X5ZOu{padding:1rem;font-size:1rem;line-height:1.5rem}.ReusableBanner_ReusableBanner__X5ZOu p{font-size:1rem;line-height:1.5rem}.ReusableBanner_ReusableBanner_informativeBanner__3k4eb{background-color:#f1f2ff}.ReusableBanner_ReusableBanner_dangerBanner__lWsUv{background-color:#fff0ef}`, "",{"version":3,"sources":["webpack://./src/features/common/components/reusable_banner/ReusableBanner.module.scss"],"names":[],"mappings":"AACA,sCACI,YAAA,CACA,cAAA,CACA,kBAAA,CAEA,wCAEI,cAAA,CACA,kBAAA,CAGJ,wDACI,wBAAA,CAGJ,mDACI,wBAAA","sourcesContent":["/* stylelint-disable */\n.ReusableBanner {\n    padding: 1rem;\n    font-size: 1rem;\n    line-height: 1.5rem;\n\n    p {\n        /* stylelint-enable */\n        font-size: 1rem;\n        line-height: 1.5rem;\n    }\n\n    &_informativeBanner {\n        background-color: #f1f2ff;\n    }\n\n    &_dangerBanner {\n        background-color: #fff0ef;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ReusableBanner": `ReusableBanner_ReusableBanner__X5ZOu`,
	"ReusableBanner_informativeBanner": `ReusableBanner_ReusableBanner_informativeBanner__3k4eb`,
	"ReusableBanner_dangerBanner": `ReusableBanner_ReusableBanner_dangerBanner__lWsUv`
};
export default ___CSS_LOADER_EXPORT___;
