import React from 'react';
import { Text } from 'spoton-lib';

import { ReusableModal } from 'features/common';
import { useAppSelector } from 'api/store';

interface IActivationModal {
    isActivationModalOpen: boolean;
    setIsActivationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onClickActivationBtn: () => void;
}

export function ActivationModal(props: IActivationModal) {
    const {
        isActivationModalOpen,
        setIsActivationModalOpen,
        onClickActivationBtn,
    } = props;

    const { isActivationRequested } = useAppSelector(
        (state) => state.merchantOnboarding,
    );

    return (
        <ReusableModal
            isOpen={isActivationModalOpen}
            title="DoorDash is ready to go"
            cancelText="Maybe later"
            confirmText="Activate now"
            onCancel={() => setIsActivationModalOpen(false)}
            onConfirm={onClickActivationBtn}
            confirmButtonColor="#2D71E8"
            isConfirmBtnLoading={isActivationRequested}
        >
            <Text>
                To start receiving orders on your SpotOn point of sale, activate
                DoorDash.
            </Text>
        </ReusableModal>
    );
}
