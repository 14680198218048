import React from 'react';
import { Hyperlink, Text } from 'spoton-lib';
// import { isFulfilled } from '@reduxjs/toolkit';

import { getConfigVar, ReusableModal } from 'features/common';
// import { useAppDispatch, useAppSelector } from 'api/store';
// import { getSignupUrl } from 'features/merchant_onboarding/MerchantOnboarding.slice';
import styles from './ConnectDoordashModal.module.scss';

interface IConnectDoordashModal {
    isConnectDoordashModalOpen: boolean;
    setIsConnectDoordashModalOpen: React.Dispatch<
        React.SetStateAction<boolean>
    >;
}

export function ConnectDoordashModal(props: IConnectDoordashModal) {
    const { isConnectDoordashModalOpen, setIsConnectDoordashModalOpen } = props;

    // const dispatch = useAppDispatch();
    // const { businessLocationId } = useAppSelector(
    //     (state) => state.merchantOnboarding,
    // );
    const onConfirm = () => {
        window.location.href = getConfigVar('REACT_APP_DOORDASH_SIGNIN_URL');
    };

    const goToSignUpLink = () => {
        // if (businessLocationId) {
        //     const res = await dispatch(getSignupUrl({ businessLocationId }));

        //     if (res && isFulfilled(res)) {
        //         if (res.payload.status === 'success') {
        //             window.location.href = res.payload.data.signup_url;
        //         }
        //     }
        // }
        window.location.href = getConfigVar('REACT_APP_DOORDASH_SIGNUP_URL');
    };

    return (
        <ReusableModal
            title="Connect DoorDash to SpotOn"
            isOpen={isConnectDoordashModalOpen}
            onCancel={() => setIsConnectDoordashModalOpen(false)}
            showCancelBtn={false}
            confirmText="Log into DoorDash"
            onConfirm={onConfirm}
            secondaryConfirmButtonText="Sign up"
            onClickSecondaryConfirmButton={goToSignUpLink}
        >
            <Text>
                Log in to your DoorDash Merchant account, or
                <Hyperlink
                    onClick={goToSignUpLink}
                    className={styles.ConnectDoordashmodal_signupLink}
                >
                    sign up for an account.
                </Hyperlink>
            </Text>
        </ReusableModal>
    );
}
