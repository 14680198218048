// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OpenStoreErrorModal_OpenStoreErrorModal_content__eahmk{flex-direction:row}`, "",{"version":3,"sources":["webpack://./src/features/merchant_onboarding/components/open_store_error_modal/OpenStoreErrorModal.module.scss"],"names":[],"mappings":"AACI,wDACI,kBAAA","sourcesContent":[".OpenStoreErrorModal {\n    &_content {\n        flex-direction: row;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"OpenStoreErrorModal_content": `OpenStoreErrorModal_OpenStoreErrorModal_content__eahmk`
};
export default ___CSS_LOADER_EXPORT___;
