import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isFulfilled, isRejectedWithValue } from '@reduxjs/toolkit';

import styles from './MerchantOnboarding.module.scss';
import { useAppDispatch, useAppSelector } from 'api/store';
import * as merchantOnboardingSlice from './MerchantOnboarding.slice';
import * as menuSlice from '../menus/Menus.slice';
import {
    MenuStatusEnum,
    StoreCandidates,
    OnboardingStatus as OnboardingStatusEnum,
    BusinessHoursExistStatus,
    ActiveModal,
} from './MerchantOnboarding.types';
import { getConfigVar } from 'features/common';
import {
    ActivationModal,
    BusinessHoursModal,
    ConnectDoordashModal,
    DdAccountCreateModal,
    LandingPage,
    Location,
    MenuStatusModal,
    OpenStoreErrorModal,
    SessionExpiredModal,
    SuccessModal,
} from './components';
import { PriceMarkupModal } from 'features/price-markup/PriceMarkupModal.component';
import { MenusModal } from 'features/menus/MenusModal.component';

export function MerchantOnboarding() {
    const [searchParams] = useSearchParams();
    const dispatch = useAppDispatch();

    const [activeModal, setActiveModal] = useState<ActiveModal>(
        ActiveModal.NONE,
    );
    const [isSessionExpired, setIsSessionExpired] = useState<boolean>(false);
    const [isUserTriesToSetBusinessHours, setIsUserTriesToSetBusinessHours] =
        useState<boolean>(false);
    const [countOfStoreIntegrateAPICall, setCountOfStoreIntegrateAPICall] =
        useState<number>(0);
    const [isStoreOpen, setIsStoreOpen] = useState<boolean>(false);

    const {
        businessLocationId,
        onboardingId,
        onboardingStatus,
        exchangeCode,
        providerStoreName,
        isActivationRequested,
        isBusinessHoursExist,
    } = useAppSelector((state) => state.merchantOnboarding);

    const { loading } = useAppSelector((state) => state.markupPrice);

    const onSyncLocationConfirm = async (selectedLocation: StoreCandidates) => {
        if (businessLocationId) {
            const res = await dispatch(
                merchantOnboardingSlice.initializeOnboarding({
                    doordash_store_uuid: selectedLocation.doordash_store_uuid,
                    location_id: businessLocationId,
                    exchange_code: localStorage.getItem('exchangeCode'),
                    provider_store_name: providerStoreName
                        ? providerStoreName
                        : '',
                    store_name: selectedLocation.name,
                    provider_store_address: selectedLocation.address,
                    business_name: selectedLocation.business_name,
                    business_uuid: selectedLocation.business_uuid,
                    dd_store_address: selectedLocation.address,
                }),
            );

            if (res && isFulfilled(res)) {
                if (res.payload.status === 'success') {
                    const getStoreIntegrationStatusRes = await dispatch(
                        merchantOnboardingSlice.getStoreIntegrationStatus({
                            businessLocationId,
                        }),
                    );
                    if (
                        getStoreIntegrationStatusRes &&
                        isFulfilled(getStoreIntegrationStatusRes)
                    ) {
                        if (
                            getStoreIntegrationStatusRes.payload.data
                                .onboarding_status ===
                            OnboardingStatusEnum.MENU_REQUESTED
                        ) {
                            setActiveModal(ActiveModal.MENU_STATUS_MODAL);
                        }
                    }
                }
            }

            if (res && isRejectedWithValue(res)) {
                if (res.payload?.errors.statusCode === 440) {
                    setIsSessionExpired(true);
                }
            }
        }
    };

    const onClickTryAgainBtn = async () => {
        if (businessLocationId) {
            const menuRequestRes = await dispatch(
                merchantOnboardingSlice.menuRequest({ businessLocationId }),
            );
            if (menuRequestRes && isFulfilled(menuRequestRes)) {
                if (menuRequestRes.payload.status === 'success') {
                    dispatch(merchantOnboardingSlice.setIsError(false));
                    dispatch(
                        merchantOnboardingSlice.setOnboardingStatus(
                            OnboardingStatusEnum.MENU_REQUESTED,
                        ),
                    );
                    if (activeModal !== ActiveModal.MENU_STATUS_MODAL) {
                        setActiveModal(ActiveModal.MENU_STATUS_MODAL);
                    }
                }
            }
        }
    };

    const changeStorePauseStatus = async (
        openStore: boolean,
        getStoreStatus: boolean,
    ) => {
        if (businessLocationId) {
            const response = await dispatch(
                merchantOnboardingSlice.storePause({
                    businessLocationId,
                    is_active: openStore,
                    reason: !openStore
                        ? 'store_self_disabled_in_their_POS_portal'
                        : undefined,
                }),
            );
            if (response && isFulfilled(response) && getStoreStatus) {
                if (response.payload.status === 'success') {
                    await dispatch(
                        merchantOnboardingSlice.getStoreIntegrationStatus({
                            businessLocationId,
                        }),
                    );
                }
            }
            return response;
        }
    };

    const onClickActivationBtn = async (openStore: boolean) => {
        setIsStoreOpen(openStore);
        if (onboardingStatus === OnboardingStatusEnum.INTEGRATION_ACTIVATED) {
            const response = await changeStorePauseStatus(openStore, false);
            if (response && isFulfilled(response)) {
                if (
                    response.payload.status === 'success' &&
                    businessLocationId
                ) {
                    const getStoreIntegrationStatusRes = await dispatch(
                        merchantOnboardingSlice.getStoreIntegrationStatus({
                            businessLocationId,
                        }),
                    );
                    if (
                        getStoreIntegrationStatusRes &&
                        isFulfilled(getStoreIntegrationStatusRes)
                    ) {
                        const data = getStoreIntegrationStatusRes.payload.data;
                        if (
                            data.onboarding_status === 'INTEGRATION_ACTIVATED'
                        ) {
                            if (data.exclusion_code === 'STORE_NOT_ACTIVE') {
                                setActiveModal(
                                    ActiveModal.OPEN_STORE_ERROR_MODAL,
                                );
                            } else {
                                setActiveModal(ActiveModal.CALL_SCHEDULE_MODAL);
                            }
                        }
                    }
                }
            }
        } else {
            if (onboardingId) {
                const activateIntegrationRes = await dispatch(
                    merchantOnboardingSlice.activateIntegration({
                        onboarding_id: onboardingId,
                    }),
                );
                if (
                    activateIntegrationRes &&
                    isFulfilled(activateIntegrationRes)
                ) {
                    if (activateIntegrationRes.payload.status === 'success') {
                        if (businessLocationId) {
                            const getStoreIntegrationStatusRes = await dispatch(
                                merchantOnboardingSlice.getStoreIntegrationStatus(
                                    {
                                        businessLocationId,
                                    },
                                ),
                            );
                            if (
                                getStoreIntegrationStatusRes &&
                                isFulfilled(getStoreIntegrationStatusRes)
                            ) {
                                const response =
                                    getStoreIntegrationStatusRes.payload.data;
                                if (
                                    response.onboarding_status ===
                                    'INTEGRATION_ACTIVATED'
                                ) {
                                    if (
                                        response.exclusion_code ===
                                        'STORE_NOT_ACTIVE'
                                    ) {
                                        setActiveModal(
                                            ActiveModal.OPEN_STORE_ERROR_MODAL,
                                        );
                                    } else {
                                        setActiveModal(
                                            ActiveModal.CALL_SCHEDULE_MODAL,
                                        );
                                        await changeStorePauseStatus(
                                            openStore,
                                            true,
                                        );
                                    }
                                } else if (
                                    response.onboarding_status ===
                                    OnboardingStatusEnum.ACTIVATION_BLOCK
                                ) {
                                    setActiveModal(ActiveModal.NONE);
                                }
                            }
                        }
                    }
                }
            }
        }
    };

    const getMenuStatus = async () => {
        if (onboardingId && businessLocationId) {
            const menuStatusRes = await dispatch(
                merchantOnboardingSlice.getMenuStatus({ onboardingId }),
            );
            if (menuStatusRes && isFulfilled(menuStatusRes)) {
                if (menuStatusRes.payload.status === 'success') {
                    if (
                        menuStatusRes.payload.data.status ===
                            MenuStatusEnum.SUCCESS ||
                        menuStatusRes.payload.data.status ===
                            MenuStatusEnum.FAILED
                    ) {
                        if (activeModal !== ActiveModal.MENU_STATUS_MODAL) {
                            setActiveModal(ActiveModal.MENU_STATUS_MODAL);
                        }
                    }
                }
            }
        }
    };

    const getStoreIntegrationStatus = async () => {
        if (businessLocationId) {
            const getStoreIntegrationStatusRes = await dispatch(
                merchantOnboardingSlice.getStoreIntegrationStatus({
                    businessLocationId,
                }),
            );
            if (
                getStoreIntegrationStatusRes &&
                isFulfilled(getStoreIntegrationStatusRes)
            ) {
                const status =
                    getStoreIntegrationStatusRes.payload.data.onboarding_status;
                if (
                    (status === OnboardingStatusEnum.MENU_BLOCK ||
                        status === OnboardingStatusEnum.MENU_QUALIFIED) &&
                    isActivationRequested === false
                ) {
                    getMenuStatus();
                } else if (status === OnboardingStatusEnum.ABANDONED) {
                    setActiveModal(ActiveModal.LOCATION_SYNC_MODAL);
                } else if (
                    status === OnboardingStatusEnum.INTEGRATION_ACTIVATED
                ) {
                    if (
                        getStoreIntegrationStatusRes.payload.data
                            .exclusion_code === 'STORE_NOT_ACTIVE'
                    ) {
                        setActiveModal(ActiveModal.OPEN_STORE_ERROR_MODAL);
                    } else {
                        setActiveModal(ActiveModal.CALL_SCHEDULE_MODAL);
                        await changeStorePauseStatus(isStoreOpen, true);
                    }
                } else if (status === OnboardingStatusEnum.ACTIVATION_BLOCK) {
                    setActiveModal(ActiveModal.NONE);
                } else if (
                    getStoreIntegrationStatusRes.payload.data
                        .business_hours_validate ===
                        BusinessHoursExistStatus.UPDATED &&
                    status === OnboardingStatusEnum.PENDING
                ) {
                    setIsUserTriesToSetBusinessHours(false);
                    if (activeModal !== ActiveModal.BUSINESS_HOURS_MODAL) {
                        setActiveModal(ActiveModal.BUSINESS_HOURS_MODAL);
                    }
                }
            }

            if (isUserTriesToSetBusinessHours) {
                setCountOfStoreIntegrateAPICall((prev) => prev + 1);
            }
        }
    };

    const getMenus = async () => {
        if (businessLocationId) {
            dispatch(menuSlice.setLoading(true));
            await dispatch(menuSlice.getMenuList({ businessLocationId }));
            dispatch(menuSlice.setLoading(false));
        }
    };

    const onTokenExchange = async () => {
        if (businessLocationId && exchangeCode) {
            const response = await dispatch(
                merchantOnboardingSlice.tokenExchange({
                    businessLocationId,
                    exchange_code: exchangeCode,
                }),
            );
            if (response && isFulfilled(response)) {
                if (response.payload.status === 'success') {
                    setActiveModal(ActiveModal.PRICE_MARKUP_MODAL);
                }
            }

            if (response && isRejectedWithValue(response)) {
                if (response.payload?.errors.statusCode === 440) {
                    setIsSessionExpired(true);
                }
            }
        }
    };

    useEffect(() => {
        if (
            onboardingStatus === OnboardingStatusEnum.MENU_REQUESTED ||
            isActivationRequested ||
            (isUserTriesToSetBusinessHours &&
                countOfStoreIntegrateAPICall <= 10)
        ) {
            const intervalTime = isUserTriesToSetBusinessHours
                ? 5000
                : parseInt(getConfigVar('REACT_APP_POLLING_INTERVAL_TIME'));

            const intervalId = setInterval(() => {
                getStoreIntegrationStatus();
            }, intervalTime);

            return () => {
                clearInterval(intervalId);
            };
        }
    }, [
        onboardingStatus,
        isActivationRequested,
        isUserTriesToSetBusinessHours,
        countOfStoreIntegrateAPICall,
    ]);

    useEffect(() => {
        const exchangeCodeFromUrl = searchParams.get('code');
        if (exchangeCodeFromUrl) {
            localStorage.setItem('exchangeCode', exchangeCodeFromUrl);
        }
    }, [searchParams]);

    useEffect(() => {
        if (
            exchangeCode &&
            !onboardingId &&
            isBusinessHoursExist === BusinessHoursExistStatus.UPDATED
        ) {
            onTokenExchange();
        }
    }, [exchangeCode, onboardingId, isBusinessHoursExist]);

    // useEffect(() => {
    //     if (!isSyncLocationModalOpen) {
    //         dispatch(
    //             merchantOnboardingSlice.setLocationErrorMessage(undefined),
    //         );
    //     }
    // }, [isSyncLocationModalOpen, exchangeCode]);

    useEffect(() => {
        getMenus();
    }, [businessLocationId]);

    return (
        <div className={styles.MerchantOnboarding}>
            <LandingPage
                setActiveModal={setActiveModal}
                setIsUserTriesToSetBusinessHours={
                    setIsUserTriesToSetBusinessHours
                }
                onTryMenuSyncAgain={onClickTryAgainBtn}
            />
            <PriceMarkupModal
                activeModal={activeModal}
                setActiveModal={setActiveModal}
                isButtonDisable={loading}
            />

            <MenusModal
                activeModal={activeModal}
                setActiveModal={setActiveModal}
                isButtonDisable={loading}
            />

            <BusinessHoursModal
                activeModal={activeModal}
                setActiveModal={setActiveModal}
                setIsUserTriesToSetBusinessHours={
                    setIsUserTriesToSetBusinessHours
                }
            />
            <ConnectDoordashModal
                activeModal={activeModal}
                setActiveModal={setActiveModal}
            />
            <DdAccountCreateModal
                activeModal={activeModal}
                setActiveModal={setActiveModal}
            />
            <Location
                activeModal={activeModal}
                setActiveModal={setActiveModal}
                onSyncLocationConfirm={onSyncLocationConfirm}
                setIsSessionExpired={setIsSessionExpired}
            />
            <MenuStatusModal
                activeModal={activeModal}
                setActiveModal={setActiveModal}
                onClickTryAgainBtn={onClickTryAgainBtn}
            />
            <ActivationModal
                activeModal={activeModal}
                setActiveModal={setActiveModal}
                onClickActivationBtn={onClickActivationBtn}
            />
            <OpenStoreErrorModal
                activeModal={activeModal}
                setActiveModal={setActiveModal}
            />
            <SuccessModal
                activeModal={activeModal}
                setActiveModal={setActiveModal}
            />
            <SessionExpiredModal
                isSessionExpired={isSessionExpired}
                setIsSessionExpired={setIsSessionExpired}
            />
        </div>
    );
}
