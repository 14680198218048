import React from 'react';
import { Text } from 'spoton-lib';

import { getConfigVar, ReusableModal } from 'features/common';
import { ActiveModal } from 'features/merchant_onboarding/MerchantOnboarding.types';
import { ConnectDoordashModalProps } from './ConnectDoordashModal.types';

export function ConnectDoordashModal(props: ConnectDoordashModalProps) {
    const { activeModal, setActiveModal } = props;

    const onConfirm = () => {
        window.location.href = getConfigVar('REACT_APP_DOORDASH_SIGNIN_URL');
    };

    return (
        <ReusableModal
            title="Do you have a DoorDash account?"
            isOpen={activeModal === ActiveModal.DOORDASH_CONNECT_MODAL}
            onCancel={() => setActiveModal(ActiveModal.NONE)}
            showCancelBtn={false}
            confirmText="Yes, I have an account"
            onConfirm={onConfirm}
            secondaryConfirmButtonText="No, sign up now"
            onClickSecondaryConfirmButton={() => {
                setActiveModal(ActiveModal.DD_ACCOUNT_CREATE_MODAL);
            }}
        >
            <Text>
                You’ll need a DoorDash account to continue. If you don’t have
                one, you can sign up now.
            </Text>
        </ReusableModal>
    );
}
