import React, { useState } from 'react';

import { ReusableModal } from '../common';
import { PriceMarkup } from './PriceMarkup.component';
import styles from './PriceMarkup.module.scss';
import { useAppSelector } from 'api/store';
import { ActiveModal } from 'features/merchant_onboarding/MerchantOnboarding.types';
import { PriceMarkupModalProps } from './PriceMarkup.types';

export function PriceMarkupModal({
    activeModal,
    setActiveModal,
    isButtonDisable,
}: PriceMarkupModalProps) {
    const { loading } = useAppSelector((state) => state.markupPrice);

    const [clickedButton, setClickedButton] = useState<string>('');

    return (
        <>
            <ReusableModal
                isOpen={activeModal === ActiveModal.PRICE_MARKUP_MODAL}
                title="Mark Up Marketplace Delivery Prices"
                cancelText="Skip for now "
                onCancel={() => {
                    setActiveModal(ActiveModal.LOCATION_SYNC_MODAL);
                    setClickedButton('');
                }}
                isConfirmBtnLoading={loading}
                confirmText="Continue"
                onConfirm={() => setClickedButton('Continue')}
                className={styles.MarkupPriceModal}
                isFooterBtnsDisabled={isButtonDisable}
            >
                <PriceMarkup
                    setActiveModal={setActiveModal}
                    isModal={true}
                    clickedButton={clickedButton}
                    setClickedButton={setClickedButton}
                />
            </ReusableModal>
        </>
    );
}
