import React, { useEffect, useState } from 'react';
import { Hyperlink, Icon, Text } from 'spoton-lib';
import { isFulfilled } from '@reduxjs/toolkit';

import { getConfigVar, ReusableModal } from 'features/common';
import styles from './DdAccountCreateModal.module.scss';
import { useAppDispatch, useAppSelector } from 'api/store';
import {
    getSignupUrl,
    getStoreIntegrationStatus,
    signupCompletionStatus,
} from 'features/merchant_onboarding/MerchantOnboarding.slice';
import { ActiveModal } from 'features/merchant_onboarding/MerchantOnboarding.types';
import { DdAccountCreateModalProps } from './DdAccountCreateModal.types';

export function DdAccountCreateModal(props: DdAccountCreateModalProps) {
    const { activeModal, setActiveModal } = props;
    const dispatch = useAppDispatch();
    const { businessLocationId, isNetNewWorkflowEnabled } = useAppSelector(
        (state) => state.merchantOnboarding,
    );
    const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] =
        useState<boolean>(true);

    const goToSignUpLink = async () => {
        if (isNetNewWorkflowEnabled) {
            if (businessLocationId) {
                const res = await dispatch(
                    getSignupUrl({ businessLocationId }),
                );

                if (res && isFulfilled(res)) {
                    if (res.payload.status === 'success') {
                        window.open(res.payload.data.signup_url);
                        setIsConfirmButtonDisabled(false);
                    }
                }
            }
        } else {
            window.open(getConfigVar('REACT_APP_DOORDASH_SIGNUP_URL'));
            setIsConfirmButtonDisabled(false);
        }
    };

    const onCreatedAnAccount = async () => {
        if (businessLocationId) {
            const response = await dispatch(
                signupCompletionStatus({
                    businessLocationId,
                    isSignupCompleted: true,
                }),
            );

            if (response && isFulfilled(response)) {
                if (response.payload.status === 'success') {
                    const getStoreIntegrationStatusRes = await dispatch(
                        getStoreIntegrationStatus({ businessLocationId }),
                    );

                    if (
                        getStoreIntegrationStatusRes &&
                        isFulfilled(getStoreIntegrationStatusRes)
                    ) {
                        setActiveModal(ActiveModal.NONE);
                    }
                }
            }
        }
    };

    useEffect(() => {
        if (activeModal !== ActiveModal.DD_ACCOUNT_CREATE_MODAL) {
            setIsConfirmButtonDisabled(true);
        }
    }, [activeModal]);

    return (
        <ReusableModal
            title="Create a DoorDash account"
            isOpen={activeModal === ActiveModal.DD_ACCOUNT_CREATE_MODAL}
            onCancel={() => {
                setActiveModal(ActiveModal.NONE);
            }}
            showCancelBtn={false}
            gap="1.25rem"
            confirmText="I created an account"
            onConfirm={onCreatedAnAccount}
            isConfirmBtnDisabled={isConfirmButtonDisabled}
            secondaryConfirmButtonText="Back"
            onClickSecondaryConfirmButton={() => {
                setActiveModal(ActiveModal.DOORDASH_CONNECT_MODAL);
            }}
        >
            <Text>
                You’ll need a DoorDash account to continue. You can
                <Hyperlink
                    className={styles.DdAccountCreateModal_link}
                    target="_blank"
                    color="#1C5DCD"
                    onClick={goToSignUpLink}
                >
                    sign up now
                    <Icon size={18} name="ExternalLinkIcon" />
                </Hyperlink>
            </Text>

            <Text>
                DoorDash has a 24-hour verification period for new accounts.
            </Text>
        </ReusableModal>
    );
}
