import React from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';

import Styles from './PaginateDropdown.module.scss';
import { PaginateDropdownProps } from './PaginateDropdown.types';

export function PaginateDropdown(props: PaginateDropdownProps) {
    const {
        onChange,
        value,
        placeholder,
        styles: propsStyles,
        className,
        loadOptions,
        trigger,
        cacheUniqs,
    } = props;
    return (
        <AsyncPaginate
            menuPlacement="top"
            onChange={onChange}
            value={value}
            data-testid="location-dropdown"
            placeholder={placeholder}
            withDividers={false}
            isOptionDisabled={(option: any) => option.isDisabled}
            styles={{
                ...propsStyles,
                container: (provided, state) => ({
                    ...provided,
                    pointerEvents: 'auto',
                    padding: '0.5rem 0',
                    ...propsStyles?.container?.(provided, state),
                }),
                control: (provided, state) => {
                    return {
                        ...provided,
                        ...propsStyles?.control?.(provided, state),
                        height: '2.75rem',
                        outline: '1px solid transparent',
                        boxShadow: 'none !important',
                    };
                },
                noOptionsMessage: (provided, state) => ({
                    ...provided,
                    ...propsStyles?.noOptionsMessage?.(provided, state),
                }),
                menu: (provided, state) => ({
                    ...provided,
                    borderRadius: '0px 0px 0.25rem 0.25rem',
                    backgroundColor: 'white',
                    boxShadow: '0px 0.375rem 0.75rem rgba(53, 63, 94, 0.1)',
                    border: '1px solid #E5E7EC',
                    maxHeight: '15.625rem',
                    width: '100%',
                    marginTop: '-0.25rem',
                    padding: 0,
                    zIndex: 2,
                    ...propsStyles?.menu?.(provided, state),
                }),
                menuList: (provided, state) => ({
                    ...provided,
                    padding: '0.3125rem',
                    maxHeight: '15rem',
                    zIndex: 2,
                    ...propsStyles?.menuList?.(provided, state),
                }),
                placeholder: (provided, state) => ({
                    ...provided,
                    display: trigger ? 'none' : 'block',
                    color: '#505A79',
                    ...propsStyles?.placeholder?.(provided, state),
                }),
                input: (provided, state) => ({
                    ...provided,
                    width: trigger ? 0 : '100%',
                    padding: '0',
                    ...propsStyles?.input?.(provided, state),
                }),
                singleValue: (provided, state) => ({
                    ...provided,
                    color: state.hasValue ? '#292b2b' : '#676B6C',
                    ...propsStyles?.singleValue?.(provided, state),
                }),
                valueContainer: (provided, state) => ({
                    ...provided,
                    height: trigger ? 0 : provided.height || '2.625rem',
                    width: trigger ? 0 : provided.width,
                    flex: trigger ? 0 : provided.flex,
                    padding: trigger ? 0 : '0.5rem 1rem',
                    overflow: trigger ? 'hidden' : 'visible',
                    ...propsStyles?.valueContainer?.(provided, state),
                }),
                indicatorSeparator: (provided, state) => ({
                    ...provided,
                    display: 'none',
                    ...propsStyles?.indicatorSeparator?.(provided, state),
                }),
                dropdownIndicator: (provided) => ({
                    ...provided,
                    color: '#6D7A8A !important',
                    padding: '0 1rem',
                }),
            }}
            menuPortalTarget={document.body}
            className={`${className} ${Styles.Dropdown}`}
            loadOptions={loadOptions}
            isSearchable={false}
            additional={{
                page: 1,
            }}
            cacheUniqs={cacheUniqs}
        />
    );
}
