// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Toast_Toast__b0OsR{gap:.75rem;max-width:29.75rem;border-radius:.75rem}.Toast_Toast__b0OsR>div>div{font-weight:400;font-size:1rem;line-height:1.5rem}.Toast_Toast__b0OsR>div>div:last-child{margin-top:0}.Toast_Toast__b0OsR>div:last-child{top:0;height:1.5rem}.Toast_Toast___success__QwfR1{background-color:#0d0a2f;border-left:none}.Toast_Toast___success__QwfR1>div>div{color:#fff}.Toast_Toast___success__QwfR1>div>div>svg>path{fill:#7dabff}.Toast_Toast___success__QwfR1>div:last-child>div>button>svg:first-child,.Toast_Toast___success__QwfR1>div:last-child>div>button>svg:last-child>circle{stroke:#fff}`, "",{"version":3,"sources":["webpack://./src/features/common/components/toast/Toast.module.scss"],"names":[],"mappings":"AACA,oBACI,UAAA,CACA,kBAAA,CACA,oBAAA,CAGI,4BACI,eAAA,CACA,cAAA,CACA,kBAAA,CAEA,uCACI,YAAA,CAIR,mCACI,KAAA,CACA,aAAA,CAIR,8BACI,wBAAA,CACA,gBAAA,CAGI,sCACI,UAAA,CAEA,+CACI,YAAA,CAMA,sJAEI,WAAA","sourcesContent":["/* stylelint-disable */\n.Toast {\n    gap: 0.75rem;\n    max-width: 29.75rem;\n    border-radius: 0.75rem;\n\n    > div {\n        > div {\n            font-weight: 400;\n            font-size: 1rem;\n            line-height: 1.5rem;\n\n            &:last-child {\n                margin-top: 0;\n            }\n        }\n\n        &:last-child {\n            top: 0;\n            height: 1.5rem;\n        }\n    }\n\n    &___success {\n        background-color: #0d0a2f;\n        border-left: none;\n\n        > div {\n            > div {\n                color: #fff;\n\n                > svg > path {\n                    fill: #7dabff;\n                }\n            }\n\n            &:last-child {\n                > div > button {\n                    > svg:first-child,\n                    > svg:last-child > circle {\n                        stroke: #fff;\n                    }\n                }\n            }\n        }\n    }\n}\n\n/* stylelint-enable */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Toast": `Toast_Toast__b0OsR`,
	"Toast___success": `Toast_Toast___success__QwfR1`
};
export default ___CSS_LOADER_EXPORT___;
