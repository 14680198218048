import React from 'react';
import { Hyperlink, Icon, Text } from 'spoton-lib';
import { isFulfilled } from '@reduxjs/toolkit';

import { useAppDispatch, useAppSelector } from 'api/store';
import { getBusinessHoursUrl, ReusableModal } from 'features/common';
import styles from './BusinessHoursModal.module.scss';
import { businessHoursConfirmation } from 'features/merchant_onboarding/MerchantOnboarding.slice';
import {
    ActiveModal,
    BusinessHoursExistStatus,
} from 'features/merchant_onboarding/MerchantOnboarding.types';
import { BusinessHoursModalProps } from './BusinessHoursModal.types';

export function BusinessHoursModal(props: BusinessHoursModalProps) {
    const { activeModal, setActiveModal, setIsUserTriesToSetBusinessHours } =
        props;
    const dispatch = useAppDispatch();

    const {
        isBusinessHoursExist,
        businessLocationId,
        loading,
        isExpressMerchant,
        isMenusConfirmed,
    } = useAppSelector((state) => state.merchantOnboarding);

    const { menus } = useAppSelector((state) => state.menus);

    const onConfirm = async () => {
        if (
            isBusinessHoursExist === BusinessHoursExistStatus.UPDATED &&
            businessLocationId
        ) {
            const response = await dispatch(
                businessHoursConfirmation({
                    isBusinessHoursUpdated: true,
                    locationId: businessLocationId,
                }),
            );
            if (response && isFulfilled(response)) {
                if (response.payload.status === 'success') {
                    if (
                        !isExpressMerchant &&
                        menus.length > 1 &&
                        !isMenusConfirmed
                    ) {
                        setActiveModal(ActiveModal.MENU_SELECTION_MODAL);
                    } else {
                        setActiveModal(ActiveModal.DOORDASH_CONNECT_MODAL);
                    }
                }
            }
        } else {
            setIsUserTriesToSetBusinessHours(true);
            window.open(
                getBusinessHoursUrl(Boolean(isExpressMerchant)),
                '_blank',
            );
        }
    };
    return (
        <ReusableModal
            isOpen={activeModal === ActiveModal.BUSINESS_HOURS_MODAL}
            title={
                isBusinessHoursExist === BusinessHoursExistStatus.UPDATED
                    ? 'Confirm your business hours'
                    : 'We are missing your business hours'
            }
            confirmText={
                isBusinessHoursExist === BusinessHoursExistStatus.UPDATED
                    ? 'I’ve confirmed my hours'
                    : 'Set up hours'
            }
            isConfirmBtnLoading={loading}
            onConfirm={onConfirm}
            onCancel={() => setActiveModal(ActiveModal.NONE)}
        >
            {isBusinessHoursExist === BusinessHoursExistStatus.UPDATED ? (
                <>
                    <Text>
                        We need to make sure your business hours are up to date
                        before proceeding. Confirm they are correct and then
                        continue.
                    </Text>
                    <Hyperlink
                        className={
                            styles.BusinessHoursModal_businessHoursPageLink
                        }
                        target="_blank"
                        color="#1C5DCD"
                        href={getBusinessHoursUrl(Boolean(isExpressMerchant))}
                    >
                        View Business Hours
                        <Icon size={18} name="ExternalLinkIcon" />
                    </Hyperlink>
                </>
            ) : (
                <Text>
                    We can’t set up DoorDash without your business hours. Can
                    you add them now?
                </Text>
            )}
        </ReusableModal>
    );
}
