import React from 'react';
import { Button } from 'spoton-lib';

import Styles from './RoundedButton.module.scss';
import { RoundedButtonProps } from './RoundedButton.types';

export function RoundedButton({
    onClick,
    buttonText,
    variant = 'primary',
    width,
    testId,
    isLoading = false,
}: RoundedButtonProps) {
    return (
        <Button
            data-testid={testId}
            variant={variant}
            onClick={onClick}
            isLoading={isLoading}
            style={{ width }}
            className={`${Styles[`RoundedButton_${variant}Button`]} ${Styles.RoundedButton}`}
        >
            {buttonText}
        </Button>
    );
}
