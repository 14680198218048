import React from 'react';
import { Button, Icon, Text } from 'spoton-lib';

import styles from './ExternalLinkButton.module.scss';
import { ExternalLinkButtonProps } from './ExternalLinkButton.types';

export function ExternalLinkButton({
    onClick,
    linkText,
    variant = 'informative',
    className = '',
    iconSize = 24,
}: ExternalLinkButtonProps) {
    return (
        <Button
            className={`${styles.ExternalLinkButton_button} ${styles[`ExternalLinkButton_${variant}Button`] ?? ''} ${className}`}
            onClick={onClick}
        >
            <Text type="sub2">{linkText}</Text>
            <Icon size={iconSize} name="ExternalLinkIcon" />
        </Button>
    );
}
