import React, { Dispatch, SetStateAction, useState } from 'react';

import { ReusableModal } from 'features/common';
import { Menus } from './Menus.component';
import styles from './Menus.module.scss';
import { useAppSelector } from 'api/store';
import { ActiveModal } from 'features/merchant_onboarding/MerchantOnboarding.types';

interface IPriceMarkupModal {
    activeModal: ActiveModal;
    setActiveModal: Dispatch<SetStateAction<ActiveModal>>;
    isButtonDisable: boolean;
}

export function MenusModal({
    activeModal,
    setActiveModal,
    isButtonDisable,
}: IPriceMarkupModal) {
    const { loading } = useAppSelector((state) => state.markupPrice);

    const [clickedButton, setClickedButton] = useState<string>('');

    return (
        <ReusableModal
            isOpen={activeModal === ActiveModal.MENU_SELECTION_MODAL}
            title="Choose your SpotOn menu for DoorDash"
            onCancel={() => {
                setActiveModal(ActiveModal.NONE);
                setClickedButton('');
            }}
            isConfirmBtnLoading={loading}
            onConfirm={() => setClickedButton('Continue')}
            secondaryConfirmButtonText="Save & Close"
            onClickSecondaryConfirmButton={() => setClickedButton('Save')}
            headerClassName={styles.MenuModalHeader}
            contentClassName={styles.MenuModalContent}
            isFooterBtnsDisabled={isButtonDisable}
            showCancelBtn={false}
            className={styles.Menus_modal}
        >
            <Menus
                setActiveModal={setActiveModal}
                isModal={true}
                clickedButton={clickedButton}
                setClickedButton={setClickedButton}
            />
        </ReusableModal>
    );
}
