import React from 'react';

export function FailBadge() {
    return (
        <svg
            width="45"
            height="47"
            viewBox="0 0 45 47"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_d_15359_45684)">
                <circle cx="21.4286" cy="21.9291" r="20.7143" fill="#FCBCBB" />
            </g>
            <path
                d="M14.7534 28.7157L28.1045 15.1443"
                stroke="#D54041"
                strokeWidth="2.85714"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.6431 15.2545L28.2145 28.6057"
                stroke="#D54041"
                strokeWidth="2.85714"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <filter
                    id="filter0_d_15359_45684"
                    x="0.714294"
                    y="1.21484"
                    width="44.2857"
                    height="44.2857"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="2.85714" dy="2.85714" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.827451 0 0 0 0 0.898039 0 0 0 0 1 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_15359_45684"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_15359_45684"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
}
