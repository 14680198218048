import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    Col,
    Container,
    Dropdown,
    Hyperlink,
    LoadingOverlay,
    Row,
    showToast,
} from 'spoton-lib';
import { isFulfilled } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';

import styles from './PriceMarkup.module.scss';
import { ArrowLeftIcon } from './assets';
import { useAppDispatch, useAppSelector } from 'api/store';
import {
    getMarkupPrice,
    setIsInitialPageLoading,
    setLoading,
    setMarkupPrice,
} from './PriceMarkup.slice';
import { URL } from 'utils/constants';
import { RoundedButton } from 'features/common';

type OptionType = {
    value: number;
    label: string;
};

interface IPriceMarkup {
    isModal?: boolean;
    clickedButton?: string;
    setClickedButton?: Dispatch<SetStateAction<string>>;
    setIsOpen?: Dispatch<SetStateAction<boolean>>;
    setLocationModal?: Dispatch<SetStateAction<boolean>>;
}

export function PriceMarkup({
    isModal = false,
    clickedButton = '',
    setClickedButton = () => {},
    setIsOpen = () => {},
    setLocationModal = () => {},
}: IPriceMarkup) {
    const originalPrice = 5.5;
    const defaultPrice = {
        label: '0 %',
        value: 0,
    };
    const [markupPricePercentage, setMarkupPricePercentage] =
        useState<OptionType>(defaultPrice);
    const [deliveryMenuPrice, setDeliveryMenuPrice] = useState<any>();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { loading, isInitialPageLoading } = useAppSelector(
        (state) => state.markupPrice,
    );
    const { businessLocationId } = useAppSelector(
        (state) => state.merchantOnboarding,
    );

    const handleMarkupChange = (option: OptionType): void => {
        setMarkupPricePercentage(option);
    };

    const getMarkupPricePercent = (): string => {
        return (originalPrice * (markupPricePercentage.value / 100)).toFixed(2);
    };

    useEffect(() => {
        const price = originalPrice + parseFloat(getMarkupPricePercent());
        setDeliveryMenuPrice((Math.ceil(price * 20 - 0.05) / 20).toFixed(2));
    }, [markupPricePercentage]);

    const getMarkupPriceValues = async () => {
        if (businessLocationId) {
            setMarkupPricePercentage(defaultPrice);
            if (!isModal) {
                dispatch(setIsInitialPageLoading(true));
            }
            const response = await dispatch(
                getMarkupPrice({ businessLocationId }),
            );
            if (!isModal) {
                dispatch(setIsInitialPageLoading(false));
            }
            if (response && isFulfilled(response)) {
                if (response.payload.status === 'success') {
                    const price = response.payload.data.price_uplift_percentage;

                    setMarkupPricePercentage({
                        label: `${price} %`,
                        value: price,
                    });
                }
            }
        }
    };

    useEffect(() => {
        getMarkupPriceValues();
    }, [businessLocationId]);

    const handleSave = async () => {
        if (markupPricePercentage?.value === undefined) {
            showToast({
                content: 'Select markup price to save.',
                containerId: 'toast-container',
                variant: 'danger',
                position: 'top-center',
            });
        } else {
            const response = await dispatch(
                setMarkupPrice({
                    locationId: businessLocationId,
                    priceUpliftPercentage: markupPricePercentage.value,
                }),
            );
            dispatch(setLoading(false));
            if (response && isFulfilled(response)) {
                if (response.payload.status === 'success' && !isModal) {
                    navigate('/doordash');
                    showToast({
                        content: 'Changes have been saved',
                        containerId: 'toast-container',
                        className: styles.PriceMarkup_toast,
                        position: 'top-center',
                    });
                }
            }

            if (isModal) {
                setClickedButton('');
                setIsOpen(false);
                setLocationModal(true);
            }
        }
    };
    useEffect(() => {
        if (isModal && clickedButton === 'Continue') {
            setClickedButton('');
            handleSave();
        }
    }, [clickedButton]);

    if (isInitialPageLoading) {
        return <LoadingOverlay />;
    }

    return (
        <>
            <Container className={styles.PriceMarkup} fluid>
                {!isModal && (
                    <>
                        <Row debug>
                            <Col
                                className={styles.PriceMarkup_col}
                                debug
                                xs={12}
                                md={12}
                                style={{
                                    paddingBottom: '20px',
                                    marginLeft: '-5px',
                                }}
                            >
                                <Hyperlink href="/doordash" color="#1C5DCD">
                                    <ArrowLeftIcon /> Previous Page
                                </Hyperlink>
                            </Col>
                        </Row>

                        <Row debug className={styles.PriceMarkup_row}>
                            <Col
                                xs={12}
                                md={8}
                                className={styles.PriceMarkup_col}
                                debug
                            >
                                <h1>Mark Up Marketplace Delivery Prices</h1>
                            </Col>
                            <Col
                                xs={12}
                                md={2}
                                offset={{ md: 1 }}
                                className={styles.PriceMarkup_col}
                                debug
                            >
                                <RoundedButton
                                    buttonText="Save"
                                    onClick={handleSave}
                                    isLoading={loading}
                                    width={162}
                                />
                            </Col>
                        </Row>
                    </>
                )}
                <Row debug className={styles.PriceMarkup_row}>
                    <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        className={styles.PriceMarkup_col}
                    >
                        <p>
                            Mark up Marketplace delivery pricing to help cover
                            the 15-30% commission.
                        </p>
                    </Col>
                </Row>
                <Row debug className={styles.PriceMarkup_row}>
                    <Col
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        xl={isModal ? 6 : 4}
                        className={styles.PriceMarkup_markupSection}
                    >
                        <h2>Select a Price Markup Percentage</h2>
                        <Dropdown
                            placeholder="Select a markup price"
                            isSearchable={false}
                            onChange={(option) =>
                                handleMarkupChange(option as OptionType)
                            }
                            options={Array.from({ length: 51 }, (_, i) => {
                                return {
                                    label: `${i} %`,
                                    value: i,
                                };
                            })}
                            value={markupPricePercentage}
                        />
                        <h5>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href={URL.DOORDASH_KNOWLEDGE_CENTER_PAGE}
                            >
                                Learn more
                            </a>{' '}
                            about avoiding double price markups
                        </h5>
                    </Col>

                    <Col
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        xl={isModal ? 6 : 4}
                        offset={{ xl: isModal ? 0 : 1 }}
                        className={styles.PriceMarkup_calculationSection}
                    >
                        <h4>How we calculate the price markup</h4>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Example original menu price</td>
                                    <td>${originalPrice.toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td>
                                        ${originalPrice.toFixed(2)} ×{' '}
                                        {markupPricePercentage.value}%
                                    </td>
                                    <td>${getMarkupPricePercent()}</td>
                                </tr>

                                <tr>
                                    <td colSpan={2}>
                                        <hr />
                                    </td>
                                </tr>

                                <tr
                                    className={
                                        styles.PriceMarkup_calculationSection_total
                                    }
                                >
                                    <td>Marketplace delivery menu price*</td>
                                    <td>${deliveryMenuPrice}</td>
                                </tr>
                                <tr
                                    className={
                                        styles.PriceMarkup_calculationSection_info
                                    }
                                >
                                    <td colSpan={2}>
                                        *Rounded to the nearest .05
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
