import { showToast } from 'spoton-lib';

import styles from './Toast.module.scss';
import { ToastProps } from './Toast.types';

export function Toast({
    content,
    position = 'top-center',
    variant = 'success',
}: ToastProps) {
    showToast({
        content,
        containerId: 'toast-container',
        position: variant === 'success' ? 'bottom-center' : position,
        variant,
        className: `${styles.Toast} ${styles[`Toast___${variant}`]}`,
    });
}
