import React from 'react';
import { Icon, Tooltip } from 'spoton-lib';

import styles from './InfoTooltip.module.scss';
import { InfoTooltipProps } from './InfoTooltip.types';

export function InfoTooltip(props: InfoTooltipProps) {
    const { tooltipContent, variant = 'top' } = props;
    return (
        <Tooltip
            tooltipClassName={styles.InfoTooltip_tooltip}
            className={styles.InfoTooltip_tooltip_wrapper}
            tooltipContent={tooltipContent}
            variant={variant}
        >
            <Icon name="InfoCircleIcon" size={18} />
        </Tooltip>
    );
}
