import {
    MenuStatusEnum,
    OnboardingStatus,
    Steps,
} from './MerchantOnboarding.types';

export const steps: Steps[] = [
    {
        key: 'is_business_hours_confirmed',
        title: 'Set Business Hours',
        isProcessCompleted: false,
        buttonText: 'Continue',
        errorButtonText: 'Try again',
    },
    {
        key: 'menuStatus',
        title: 'Connect DoorDash to SpotOn',
        isProcessCompleted: false,
        buttonText: 'Continue',
        errorButtonText: 'Try again',
    },
    {
        key: 'activateStatus',
        title: 'Begin Receiving DoorDash Orders',
        isProcessCompleted: false,
        buttonText: 'Continue',
        errorButtonText: 'Try Again',
    },
];

export const abandonedStatusErrorMessage = (
    exclusionCode: string,
    exclusionCodeDetails: string,
) => {
    switch (exclusionCode) {
        case 'DUPLICATE_LOCATION_ID':
            return 'The location you selected has already been onboarded.';
        case 'STORE_LIVE_ON_REQUESTED_POS_PROVIDER':
            return 'This store is already live on the requested POS integration with this location.';
        default:
            return exclusionCodeDetails;
    }
};

export const description = ({
    isPending,
    isError,
    isProcessCompleted,
    key,
    storeName,
    providerStoreName,
    onboardingStatus,
    exclusionCodeDetails,
    exclusionCode,
    isActivationRequested,
}: {
    isPending: boolean;
    isError: boolean;
    isProcessCompleted: boolean;
    key: string;
    storeName?: string;
    providerStoreName: string | null;
    onboardingStatus?: OnboardingStatus;
    exclusionCodeDetails: string;
    exclusionCode?: string;
    isActivationRequested: boolean;
}) => {
    switch (key) {
        case 'locationStatus':
            if (onboardingStatus === OnboardingStatus.ABANDONED && isError) {
                if (exclusionCode === 'STORE_LIVE_ON_REQUESTED_POS_PROVIDER') {
                    return 'This store is already live on the requested POS integration with this location.';
                } else {
                    return exclusionCodeDetails;
                }
            } else if (storeName && providerStoreName) {
                return `${providerStoreName}(${storeName})`;
            }
            return 'Pick the location you want to connect to your SpotOn POS.';
        case 'menuStatus':
            if (isPending && !isError) {
                return 'Menu Sync in progress';
            } else if (isProcessCompleted) {
                return 'Menu is live';
            } else if (
                onboardingStatus === OnboardingStatus.MENU_BLOCK &&
                isError
            ) {
                return 'We were unable to sync your menu.';
            } else {
                return 'Sync, preview, and confirm your menu on DoorDash.';
            }
        case 'activateStatus':
            if (isPending && isActivationRequested) {
                return 'Activate Integration in progress';
            } else {
                return 'Turn DoorDash on to start receiving orders on your POS.';
            }
    }
};

export const MenuModalTitle = (status: MenuStatusEnum | undefined) => {
    switch (status) {
        case MenuStatusEnum.SUCCESS:
            return 'Menu synced and ready to review';
        case MenuStatusEnum.FAILED:
            return 'Menu sync failed';
        default:
            return 'Menu sync in progress, please wait';
    }
};

export const isToday = (date: string) => {
    const today = new Date();

    const todayString = today.toLocaleDateString();
    const givenDateString = new Date(date).toLocaleDateString();

    return todayString === givenDateString;
};

export const getLocaleTime = (date: string) => {
    return new Date(date).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
    });
};

export const getLocaleDate = (date: string) => {
    return new Date(date).toLocaleDateString();
};
